import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();

var tag = window.location.host.includes("dev") || window.location.host.includes("localhost")
      ? "Bleudine,ClientWebApp,Development"
      : window.location.host.includes("stg")
      ? "Bleudine,ClientWebApp,Staging"
      : "Bleudine,ClientWebApp,Production";

logger.push({ 'logglyKey': '785630b3-0803-4745-842e-613f75aaf056','sendConsoleErrors' : true,'tag': tag });

export default logger;