import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authApi } from "api/authApi";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      <Component {...props} />
      // localStorage.getItem("user") !== null ? (
      //   <Component {...props} />
      // ) : (
      //   <Redirect
      //     to={{ pathname: "/signin", state: { from: props.location } }}
      //   />
      // )
    }
  />
);
