import React, { Component } from 'react'
import App from 'base-shell/lib'
import MUIConfig from 'material-ui-shell/lib'
import merge from 'base-shell/lib/utils/config'
import _config from './config'
import { ErrorBoundary } from 'react-error-boundary'
import logger from 'utils/logger.js';

const config = merge(MUIConfig, _config)

function ErrorFallback({error}) {
  return null
}

const myErrorHandler = (error, info) => {
  logger.push({ error, info });
}

export default class Demo extends Component {
  render() {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
        <App config={config} />
      </ErrorBoundary>
    )
  }
}
