/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'
import { PrivateRoute } from "components/PrivateRoute";
import { Route } from 'react-router-dom'

const Menu = lazy(() => import('../container/Menu'))
const Home = lazy(() => import('../container/Home'))
const MenuCustomize = lazy(() => import('../container/MenuCustomize'))
const Receipt = lazy(() => import('../container/Receipt'))
const Order = lazy(() => import('../container/Order'))
const SignIn = lazy(() => import('../container/SignIn'))
const Reservation = lazy(() => import('../container/ReservationBoard'))

const routes = [
  <Route path="/signin/:outletId/:tableNo" exact component={SignIn} />,
  <PrivateRoute path="/home/:outletId/:tableNo" exact component={Home} />,
  <PrivateRoute path="/menu" exact component={Menu} />,
  <PrivateRoute path="/menuCustomize" exact component={MenuCustomize} />,
  <PrivateRoute path="/receipt" exact component={Receipt} />,
  <PrivateRoute path="/order" exact component={Order} />,
  <PrivateRoute path="/reservation/merchant/:merchantId" exact component={Reservation}/>
]

export default routes
