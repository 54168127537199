export const apiConfig = {
  url: process.env.NODE_ENV === "production" ? "" : "https://admin.dev.bleudine.com",
  path: "/api",
  getToken: function () {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.token : "";
  },
  getCurrentUserId: function () {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.id : 0;
  },
  getUserType: function () {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.type : 4;
  }
};
