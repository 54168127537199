import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const authApi = {
  verifyGuestOTP,
  getGuest
};

function verifyGuestOTP(otp, tableNo) {
  const url = `${apiConfig.url}${apiConfig.path}/auth/guest/verify/${otp}?tableNo=${tableNo}`;
  // const url = `http://localhost:5003/api/auth/guest/verify/${otp}?tableNo=${tableNo}`;

  const requestOptions = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => {
      if (user && user.token) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });

  
}

function getGuest() {
  const url = `${apiConfig.url}${apiConfig.path}/auth/guest`;
  // const url = `http://localhost:${localhostPort}/api/auth/guest`;

  const requestOptions = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => {
      if (user && user.token) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}
